
.prompt-text {
  font-size: 12px;
  color: #aeb2b9;
  margin-top: 20px;
  padding-left: 30px;
}
.upload-box {
  padding-left: 30px;
  display: flex;
  .upload-video-box {
    width: 100px;
    height: 100px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .upload-instance {
      display: none;
    }
    .process-line {
      width: 10%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #0a84ff;
      border-radius: 2px;
      z-index: 99;
    }
    .process-bg {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 2px;
      background-color: #a1cbf7;
    }
    .upload-video-components {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        margin-top: 10px;
        color: #c4c4c4;
      }
    }
  }
  .mp4-preview-wrap {
    position: relative;
    width: 100px;
    height: 100px;
    video {
      width: 100%;
      height: 100%;
    }
    background-color: rgba(0, 0, 0, 0.1);
    .video-btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.8);
      .video-btn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        font-size: 12px;
        &.preview {
          color: #0a84ff;
        }
        &.delete {
          color: #ee2020;
        }
      }
    }
  }
}
.video-dialog {
  width: 100%;
  height: 740px;
  background-color: rgba(0, 0, 0, 0.5);
  video {
    width: 100%;
    height: 100%;
  }
}
