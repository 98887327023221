
.set-content {
  margin: 20px 10px 0;
  background: #f0f2f5;
  padding: 20px 10px;
  border-radius: 4px;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
  text-align: center;
}
