
.goods-add-dialog {
  padding: 20px;
  ::v-deep .el-overlay {
    z-index: 1200 !important;
  }
}
.module-title {
  margin: 0 10px;
  line-height: 44px;
  border-bottom: 1px solid #e8e6eb;
}
.no-good {
  padding: 50px 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 154px;
  }
  .text {
    color: #d1d1d1;
    font-size: 18px;
    margin-top: 5px;
  }
}
.good-list {
  padding: 0 10px;
  .good-item {
    margin-top: 20px;
    padding: 10px;
    background: #f0f2f5;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    .good-img {
      width: 70px;
      height: 70px;
      border-radius: 4px;
      overflow: hidden;
    }
    .good-detail {
      width: 1%;
      flex: 1;
      margin-left: 10px;
      .text {
        color: #606266;
        height: 42px;
      }
      .info {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        .price {
          display: flex;
          align-items: center;
          .red {
            color: #ff3636;
          }
          .grey {
            color: #9a9a9a;
            margin-left: 4px;
            font-size: 12px;
            text-decoration: line-through;
          }
        }
        .operate {
          .el-link + .el-link {
            margin-left: 10px;
          }
        }
      }
    }
    &.current {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 4px solid #67c23a;
        border-radius: 4px;
      }
    }
  }
}
.form-box {
  background: #f0f2f5;
  padding: 20px 0 1px;
  border-radius: 4px;
  .form-title {
    width: 100px;
    text-align: right;
    padding-right: 12px;
    margin-bottom: 20px;
  }
  & + .form-box {
    margin-top: 20px;
  }
}

.upload-box {
  padding-left: 30px;
  display: flex;
}
.upload-file {
  .upload-btn {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 4px;
    color: #c4c4c4;
    .el-icon {
      font-size: 18px;
      font-weight: 500;
    }
    .text {
      margin-top: 6px;
    }
  }
}
.img-box {
  display: flex;
  align-items: center;
  .img-list {
    display: flex;
    .img-item {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      .del-btn {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 24px;
        width: 24px;
        text-align: center;
        color: #ff2424;
        background: #fff;
        cursor: pointer;
      }
    }
  }
}
.prompt-text {
  font-size: 12px;
  color: #aeb2b9;
  margin-top: 20px;
  padding-left: 30px;
}
.top10 {
  ::v-deep .el-form-item__error {
    top: 10px;
  }
}
