
.set-content {
  margin: 20px 10px 0;
  /*background: #F0F2F5;*/
  /*padding: 20px 10px;*/
  border-radius: 4px;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .prompt {
    font-size: 12px;
    color: #9797a1;
  }
}
.upload-demo {
  :deep(.el-upload) {
    width: 100%;
  }
}
.img-list {
  .img-item {
    background: #f0f2f5;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    .img-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #606266;
    }
    .img-box {
      margin-top: 20px;
      width: 200px;
      height: 62.625px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
