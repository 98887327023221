
.set-content {
  margin: 20px 10px 0;
  background: #f0f2f5;
  padding: 20px 10px;
  border-radius: 4px;
  position: relative;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
}
.checkboxGroup {
  margin-top: 10px;
  :deep(.el-checkbox) {
    display: flex;
    margin-right: 0;
    font-weight: normal;
    padding: 0 10px;
  }
}
.sort-list {
  position: absolute;
  background: #fff;
  border: 1px solid #dde6f3;
  left: 10px;
  right: 10px;
  top: 111px;
  .sort-bottom {
    padding: 10px;
    border-top: 1px solid #dde6f3;
    text-align: right;
    margin-top: 10px;
  }
}
