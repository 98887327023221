
.w-button {
  height: 30px;
  padding: 0 12px;
  color: #696970;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  .iconfont {
    margin-right: 5px;
    font-size: 16px;
  }
  &:hover {
    color: #1cb064;
  }
  &.blue {
    color: #fff;
    background: #1cb064;
    &:hover {
      background: #22be6e;
    }
  }
  & + .w-button {
    margin-left: 20px;
  }
  &.h40 {
    height: 40px;
    font-size: 14px;
  }
}

.decorate-container {
  width: 100%;
  height: 100%;
  background: #eef0f4;
}
.decorate-content {
  display: flex;
  height: calc(100vh - 50px);
  .decorate-main {
    // width: 1%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 96px 140px 56px 140px;
  }
  .main-box {
    background: #fff;
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
    .float-tab {
      position: absolute;
      left: 0;
      top: -60px;
      color: #696970;
      background: #e3e5e9;
      line-height: 30px;
      padding: 0 20px;
      border-radius: 4px;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -30px;
      height: 30px;
      border-left: 1px dashed #bbbbbb;
    }
    .float-download {
      position: absolute;
      top: -58px;
      right: -110px;
      &:before {
        content: "";
        position: absolute;
        left: 65px;
        top: 30px;
        height: 26px;
        border-left: 1px dashed #bbbbbb;
      }
      .title {
        color: #696970;
        background: #e3e5e9;
        line-height: 30px;
        padding: 0 20px;
        border-radius: 4px;
      }
      .iconfont {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        display: block;
        line-height: 40px;
        text-align: center;
        color: #b0b0ba;
        font-size: 18px;
        margin-top: 26px;
        margin-left: 45px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover,
        &.current {
          color: #1cb064;
        }
      }
    }
  }
  .set-container {
    width: 370px;
    background: #f8f9fa;
    .set-title {
      color: #696970;
      line-height: 50px;
      border-bottom: 1px solid #e3e5e9;
      margin: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .prompt-text {
      color: #999;
      font-size: 12px;
      margin: 12px 10px 0;
    }
    .red {
      color: #ff4747;
    }
    .set-bottom {
      margin: 60px 10px 20px;
    }
  }
}

.main-content {
  min-width: 760px;
  height: 100%;
  .shop-sign {
    width: 100%;
    height: 0;
    padding-bottom: 6.25%;
    position: relative;
    .box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fafafa;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .class-box {
    width: 100%;
    background: #fff;
    .box {
      max-width: 760px;
      height: 30px;
      margin: 0 auto;
      line-height: 30px;
      color: #666;
      font-size: 12px;
      display: flex;
    }
    .add-btn {
      color: #999;
      margin-left: 50px;
      cursor: pointer;
    }
    .item {
      position: relative;
      cursor: pointer;
      transition: all 0.3s;
      max-width: 100px;
      & + .item {
        margin-left: 20px;
      }
      .class-levelOne {
        .el-icon {
          margin-left: 4px;
        }
      }
      .class-levelTwo {
        position: absolute;
        z-index: 9999;
        background: #fff;
        top: 25px;
        left: 0;
        border: 1px solid #e3e5e9;
        min-width: 60px;
        max-width: 100px;
        display: none;
      }
      &:hover {
        .class-levelTwo {
          display: block;
        }
      }
    }
  }
  ::v-deep .el-scrollbar__wrap {
    height: calc(100% + 17px);
  }
}

.swiper-box {
  width: 100%;
  height: 0;
  padding-bottom: 32.8125%;
  background: #fafafa;
  position: relative;
  .carousel-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    :deep(.el-carousel__container) {
      height: 100%;
    }
    :deep(.el-carousel__item) {
      display: flex;
      align-items: center;
      justify-content: center;
      .item {
        max-width: 100%;
        max-height: 100%;
      }
    }
    :deep(.el-carousel__button) {
      width: 10px;
      height: 10px;
      border: none;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
    }
    :deep(.is-active .el-carousel__button) {
      background: #1cb064;
    }
  }
}
.sort-box {
  .box {
    max-width: 760px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 14px 0;
  }
  .sort-item {
    display: flex;
    font-size: 12px;
    border: 1px solid #e3e5e9;
    border-radius: 2px;
    line-height: 20px;
    padding: 0 10px;
    span {
      min-width: 22px;
    }
    .iconfont {
      font-size: 12px;
      transform: scale(0.5);
      margin-left: 6px;
    }
    & + .sort-item {
      margin-left: 7px;
    }
    &:first-child {
      color: #1cb064;
      background: #fafafa;
    }
  }
}
.good-list {
  margin-bottom: 40px;
  .box {
    max-width: 760px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  .good-item {
    width: calc(25% - 15px);
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    /*padding: 10px;*/
    &:nth-of-type(4n + 1) {
      margin-left: 0;
    }
    .good-image {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      .img-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fafafa;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .good-price {
      color: #cc0000;
      margin-top: 6px;
      line-height: 1;
    }
    .good-name {
      color: #000;
      margin-top: 6px;
      line-height: 18px;
      height: 36px;
    }
  }
}

.floatBorder {
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px solid #1cb064;
    z-index: 999;
  }
}
.floatBorder1 {
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 10px solid #fff;
    z-index: 999;
    outline: 4px solid #1cb064;
  }
}
