
.set-content {
  margin: 20px 10px 0;
  background: #f0f2f5;
  padding: 20px 10px;
  border-radius: 4px;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .prompt {
    font-size: 12px;
    color: #9797a1;
  }
}

.category-table {
  width: 100%;
  .iconfont {
    font-size: 12px;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    color: #67c23a;
    cursor: pointer;
  }
  .line {
    display: flex;
    align-items: center;
    &.body {
      height: 40px;
      margin-bottom: 5px;
    }
    .td {
      display: flex;
      padding: 0 5px;
      .down {
        cursor: pointer;
        color: #7d7d7d;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        margin-right: 4px;
        &.current {
          transform: rotate(90deg);
        }
      }
      .placeIcon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }
    &:hover {
      background: #fafafa;
    }
  }
  .category-sort {
    ::v-deep .el-input__inner {
      text-align: center;
      padding: 0;
    }
  }
}
